<template>
  <div id="wrapper" class="divided">

    <!-- One -->
    <section class="banner style1 orient-left content-align-left image-position-right fullscreen onload-image-fade-in onload-content-fade-right">
      <div class="content">
        <img src="/images/y_pedagogie.png" class="service-name" width="50%" alt="" />
        <p>
          En alliant la théorie et la pratique, je vous propose un coaching sur mesure en fonction de vos besoins, dans le respect de vos valeurs et de votre contexte de vie.
          Je propose également des conférences et des ateliers afin de partager mes connaissances.
        </p>

        <router-link class="button" to="/prices">
            Tarifs
        </router-link>
      </div>
      <div class="image">
        <img src="/images/pedagogie/1.jpg" alt="" />
      </div>
    </section>

    <!-- Two -->
    <section class="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in" id="first">
      <div class="content">
        <ul>
          <li>Cours pratiques (alimentation vivante, locale, sans gluten, etc.)</li>
          <li>Accompagnement en supermarché (déchiffrage des étiquettes, sélection des aliments, etc.)</li>
          <li>Réorganisation de placards, réfrigérateur, etc</li>
          <li>Conférences et ateliers (entreprises, écoles, centres sportifs, groupes, etc.)</li>
        </ul>
      </div>
      <div class="image">
        <img src="/images/pedagogie/2.jpg" alt="" />
      </div>
    </section>
  </div>
</template>
